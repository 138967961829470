import { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles, Tabs, Tab } from '@material-ui/core';
import PersonOutline from '@material-ui/icons/PersonOutline';
import CheckCircle from '@material-ui/icons/CheckCircle';
import i18n from 'assets/i18n';
import modalStyle from 'assets/jss/modalStyle';
import commonStyle from 'assets/jss/commonStyles';
import { VmsDialog } from 'components';
import SwipeableViews from 'react-swipeable-views';
import VisitService from '../../../../services/VisitService';
import { openSnackbar } from 'components/common/bars/SnackBar';
import PorterVisitTab from './PorterVisitTab';
import PorterEventsTab from './PorterEventsTab';
import PorterDocsTab from './PorterDocsTab';

export const createEmptyPorterState = () => {
  return {
    open: false,
    visit: {
      _id: null,
      user_status: null,
      user: {
        _id: null,
        name: null,
        company: null,
      },
      host: {
        name: null,
      },
      assistant: null,
      deputy: null,
      note: null,
      events: [],
      arrival: null,
      departure: null,
      porterNote: null,
      documents: [],
      visitorCard: null,
    },
    tabs: {
      value: 0,
    },
  };
};

export const createPorterState = (porterState, visit) => {
  return {
    open: porterState.open,
    visit: {
      _id: visit._id,
      user_status: visit.user.status,
      user: {
        _id: visit.user._id,
        name: visit.user.name,
        company: visit.user.company,
      },
      host: {
        name: visit.host.name,
      },
      assistant: visit.host.assistant ? visit.host.assistant.name : undefined,
      deputy: visit.host.deputy ? visit.host.deputy.name : undefined,
      note: visit.note,
      events: visit.events,
      arrival: new Date(visit.timeFrom),
      departure: new Date(visit.timeTo),
      porterNote: visit.porterNote,
      documents: visit.documents,
      visitorCard: visit.visitorCard,
    },
    tabs: {
      value: porterState.tabs.value,
    },
  };
};

const styles = (theme) => ({
  ...modalStyle(theme),
  ...commonStyle(theme),
  tabs: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    width: '100%',
  },
  tab: {
    color: theme.palette.common.white,
    fontWeight: 500,
    fontSize: 24,
    lineHeight: 1.2,
  },
  tabIndicator: {
    height: 4,
    backgroundColor: theme.palette.tabIndicator,
  },
  tabContent: {
    flex: 1,
    '& .react-swipeable-view-container': {
      height: '100%',
    },
  },
});

class PorterDialog extends Component {
  handleDialogClose = () => {
    this.props.onChange(createEmptyPorterState());
  };

  handleChangeTabs = (event, value) => {
    this.props.porterState.tabs = { value };
    this.forceUpdate();
  };

  handleChangeTabsIndex = (index) => {
    this.props.porterState.tabs = { value: index };
    this.forceUpdate();
  };

  handlePorterChangeData = (event) => {
    const { visit } = this.props.porterState;
    let fieldValue = event.target.value;
    let fieldName = event.target.name;
    visit[fieldName] = fieldValue;
    this.props.porterState.visit = visit;
    this.forceUpdate();
  };

  savePorterNote = async () => {
    try {
      const { visit } = this.props.porterState;
      let self = this;
      if (visit.visitorCard !== '') {
        await VisitService.checkVisitorCards([
          {
            visitorCard: visit.visitorCard,
            visit_id: visit._id,
          },
        ]);
      }
      VisitService.updatePorterData(visit._id, {
        porterNote: visit.porterNote,
        visitorCard: visit.visitorCard,
      })
        .then(function () {
          openSnackbar(i18n.t('snackbar_invite_save_success'));
          self.handleDialogClose();
        })
        .catch(function (error) {
          openSnackbar(error.message);
        });
    } catch (error) {
      console.log(error);
      openSnackbar(error.message);
    }
  };

  render() {
    const { classes, porterState, selectedDate, handlePrintBadge } = this.props;

    return (
      <VmsDialog
        open={porterState.open}
        onClose={this.handleDialogClose}
        closeButtonDataCy="invitation-button-close"
        dialogHeaderIcon={<PersonOutline />}
        dialogHeaderTitle={i18n.t('visitor_dialog_title')}
        buttonIcon={<CheckCircle className={classes.fabIcon} />}
        buttonText={i18n.t('save')}
        buttonAction={this.savePorterNote}
        buttonDataCy="invitation-button-save"
        buttonDeleteText={i18n.t('menu_print_badge')}
        buttonDeleteAction={handlePrintBadge(porterState.visit)}
        buttonDeleteDataCy="invitation-button-print-badge"
        saveDisabled
      >
        <Tabs
          value={this.props.porterState.tabs.value}
          onChange={this.handleChangeTabs}
          variant="fullWidth"
          classes={{ indicator: classes.tabIndicator }}
          className={classes.tabs}
        >
          <Tab
            className={classes.tab}
            label={i18n.t('invite_form_tab_visitor')}
          />
          <Tab className={classes.tab} label={i18n.t('invite_form_tab_docs')} />
          <Tab
            className={classes.tab}
            label={i18n.t('invite_form_tab_events')}
          />
        </Tabs>
        <SwipeableViews
          axis={this.props.theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={porterState.tabs.value}
          className={classes.tabContent}
          onChangeIndex={this.handleChangeTabsIndex}
        >
          <PorterVisitTab
            porterState={porterState}
            handlePorterChangeData={this.handlePorterChangeData}
          />
          <PorterDocsTab porterState={porterState} />
          <PorterEventsTab
            porterState={porterState}
            selectedDate={selectedDate}
          />
        </SwipeableViews>
      </VmsDialog>
    );
  }
}

PorterDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  porterState: PropTypes.object.isRequired,
  selectedDate: PropTypes.object.isRequired,
  handlePrintBadge: PropTypes.func.isRequired,
};

export default withStyles(styles, { withTheme: true })(PorterDialog);
