import { configuration_group } from '../../AppSettings';

class ConfigurationModel {
  /**
   * Configuration constructor.
   *
   * @param {string} [id=null] Configuration ID
   * @param {string} key Configuration key
   * @param {any} value Configuration value
   * @param {string} value Configuration group
   * @param {string} [description=null] Configuration description
   */
  constructor(
    id,
    key,
    value,
    group,
    description,
    required_for_entrance,
    expires_in,
    random_order_of_questions,
    number_of_questions,
    priority
  ) {
    this._id = id;
    this.key = key;
    this.value = value;
    this.value = value;
    this.group = group;
    this.description = description;
    this.required_for_entrance = required_for_entrance;
    this.expires_in = expires_in;
    this.random_order_of_questions = random_order_of_questions;
    this.number_of_questions = number_of_questions;
    this.priority = priority;
    // if config value is within types constants, it means that this is 'type configuration'
    this.isTypeConfiguration = this.group === configuration_group.TYPES;
  }

  /**
   * Creates JSON representation of ConfigurationModel object.
   *
   * @returns {string} JSON representation of ConfigurationModel object
   */
  toJSON() {
    const data = {
      key: this.key,
      value: this.value,
      group: this.group,
      description: this.description,
      required_for_entrance: this.required_for_entrance,
      expires_in: this.expires_in,
      random_order_of_questions: this.random_order_of_questions,
      number_of_questions: this.number_of_questions,
    };
    return JSON.stringify(data);
  }

  getDataForSettingsPage() {
    return {
      _id: this._id,
      name: this.isTypeConfiguration ? this.key : this.value,
      type: this.isTypeConfiguration ? this.value : this.key,
      description: this.description,
      required_for_entrance: this.required_for_entrance,
      expires_in: this.expires_in,
      random_order_of_questions: this.random_order_of_questions,
      number_of_questions: this.number_of_questions,
      originalData: this,
    };
  }
}

ConfigurationModel.fromJson = function (json) {
  return new ConfigurationModel(
    json._id,
    json.key,
    json.value,
    json.group,
    json.description,
    json.required_for_entrance,
    json.expires_in,
    json.random_order_of_questions,
    json.number_of_questions,
    json.priority
  );
};

ConfigurationModel.fromSettingsPageData = function (data) {
  return new ConfigurationModel(
    null,
    data.name,
    data.type,
    configuration_group.TYPES,
    null,
    data.required_for_entrance,
    data.expires_in,
    data.random_order_of_questions,
    data.number_of_questions
  );
};

ConfigurationModel.getSettingsPageUpdateBody = function (
  newValues,
  isTypeConfiguration
) {
  let update = {};
  if (isTypeConfiguration) {
    update.key = newValues.name;
    update.value = newValues.type;
  } else {
    update.value = newValues.name;
  }
  update.required_for_entrance = newValues.required_for_entrance;
  update.expires_in = newValues.expires_in;
  update.random_order_of_questions = newValues.random_order_of_questions;
  update.number_of_questions = newValues.number_of_questions;
  update.priority = newValues.priority;
  return update;
};

ConfigurationModel.fromLocationPageData = function (data) {
  return new ConfigurationModel(
    null,
    data.key,
    data.value,
    configuration_group.LOCATION,
    null
  );
};

ConfigurationModel.getLocationPageUpdateBody = function (newValues) {
  let update = {
    value: newValues.value,
  };
  return update;
};

ConfigurationModel.getEmptyDataForSettingsPage = function () {
  return {
    name: '',
    type: '',
    originalData: null,
  };
};

export default ConfigurationModel;
