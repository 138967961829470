import {
  withStyles,
  Grid,
  AppBar,
  Toolbar,
  Avatar,
  Typography,
} from '@material-ui/core';
import { DocumentDetail, LanguageMenu } from 'components';
import LogoutPage from 'containers/LogoutPage';
import { Route, Switch } from 'react-router';
import TabletMainPage from './TabletMainPage';
import useLogout from './useLogout';
import useUserFromQR from './useUserFromQR';

const styles = (theme) => ({
  toolBar: {
    padding: `0 ${theme.spacing()}px`,
  },
  logoImg: {
    width: 218,
    height: 40,
  },
  toolBarItem: {
    flex: 1,
  },
  content: {
    marginTop: 64,
    height: 'calc(100vh - 64px)',
  },
  avatar: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    marginRight: 10,
  },
  userName: {
    textTransform: 'uppercase',
  },
});

const SigningTabletPage = ({
  classes,
  companyLogo,
  companyName,
  handleLanguage,
}) => {
  const increment = useLogout();
  const [user, enableScanner, logOutUser, login] = useUserFromQR();

  return (
    <>
      <AppBar position="fixed" color="inherit">
        <Toolbar className={classes.toolBar}>
          <Grid container>
            <Grid
              className={classes.toolBarItem}
              item
              container
              justifyContent="flex-start"
            >
              {user && (
                <Grid container alignItems="center">
                  <Avatar
                    alt="Avatar"
                    src={'/images/avatar.png'}
                    className={classes.avatar}
                  />
                  <Typography
                    className={classes.userName}
                    variant="body1"
                    color="inherit"
                  >
                    {user.name}
                  </Typography>
                </Grid>
              )}
            </Grid>
            <Grid
              className={classes.toolBarItem}
              item
              container
              justifyContent="center"
            >
              {companyLogo && (
                <img
                  data-cy="signing-tablet-image-logo"
                  src={companyLogo}
                  alt="Company logo"
                  title={companyName}
                  className={classes.logoImg}
                  onClick={increment}
                />
              )}
            </Grid>
            <Grid
              className={classes.toolBarItem}
              item
              container
              justifyContent="flex-end"
            >
              <LanguageMenu handleLanguage={handleLanguage} />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <div className={classes.content}>
        <Switch>
          <Route path="/logout" component={LogoutPage} />
          <Route
            path="/documents/detail"
            render={(props) => <DocumentDetail {...props} user={user} />}
          />
          <Route
            path="/"
            render={() => (
              <TabletMainPage
                user={user}
                enableScanner={enableScanner}
                logOut={logOutUser}
                login={login}
              />
            )}
          />
        </Switch>
      </div>
    </>
  );
};

export default withStyles(styles)(SigningTabletPage);
