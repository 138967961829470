import {
  withStyles,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import PropTypes from 'prop-types';
import commonStyle from 'assets/jss/commonStyles';
import { RequiredIcon } from 'components/common/icon';

const styles = (theme) => ({
  ...commonStyle(theme),
});

const vmsDialogRowSelectArray = (props) => {
  const {
    placeholder,
    iconLeft,
    onChange,
    classes,
    rowClass,
    selectTypes,
    selectedType,
    inputName,
    disabled,
    multiple,
    renderValue,
    mapOfItem,
    selectDataCy,
    itemDataCy,
    required,
  } = props;

  return (
    <Grid
      container
      className={rowClass}
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
    >
      {iconLeft && (
        <div className={classes.icon}>
          <RequiredIcon required={required}>{iconLeft}</RequiredIcon>
        </div>
      )}
      <Grid item className={classes.rowContent}>
        <FormControl className={classes.formControl} fullWidth>
          <InputLabel
            htmlFor={inputName}
            shrink={false}
            style={{
              transform: 'translate(0, 8px) scale(1)',
            }}
            disabled={disabled}
          >
            {!selectedType ? placeholder : selectedType}
          </InputLabel>
          <Select
            data-cy={selectDataCy}
            multiple={multiple}
            value={selectedType}
            onChange={onChange}
            inputProps={{
              name: inputName,
              id: inputName,
            }}
            style={{
              marginTop: 0,
            }}
            IconComponent={() => (
              <ArrowDropDown className={classes.iconSelect} />
            )}
            disabled={disabled}
            renderValue={renderValue}
          >
            {selectTypes.map((option, index) => {
              return (
                <MenuItem
                  data-cy={itemDataCy}
                  key={index}
                  selected={mapOfItem(option) === selectedType}
                  value={mapOfItem(option)}
                >
                  {mapOfItem(option)}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
};

vmsDialogRowSelectArray.propTypes = {
  classes: PropTypes.object.isRequired,
  iconLeft: PropTypes.object,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  textValue: PropTypes.string,
  rowClass: PropTypes.string.isRequired,
  selectTypes: PropTypes.array.isRequired,
  selectedType: PropTypes.any.isRequired,
  inputName: PropTypes.string,
  disabled: PropTypes.bool,
  mapOfItem: PropTypes.func.isRequired,
  selectDataCy: PropTypes.string,
  itemDataCy: PropTypes.string,
};

export default withStyles(styles)(vmsDialogRowSelectArray);
