import { withStyles, Grid } from '@material-ui/core';
import i18n from 'assets/i18n';
import commonStyle from 'assets/jss/commonStyles';
import { colors } from 'assets/UISettings';
import { RequiredIcon } from 'components/common/icon';

import { PhoneInput } from 'react-international-phone';

const styles = (theme) => ({
  ...commonStyle(theme),
  PhoneInput: {
    '& .react-international-phone-input': {
      width: '100%',
      border: 'none !important',
      borderBottom: `1px solid ${colors.whiteFive} !important`,
      padding: '6px 0 7px !important',
      letterSpacing: 0.5,
      font: 'inherit !important',
    },
    '& .react-international-phone-input:focus': {
      borderBottom: `2px solid orange !important`,
    },
    '& .react-international-phone-input:hover': {
      borderBottom: `2px solid orange !important`,
    },

    '& div button': {
      border: 'none !important',
    },
  },
});

const VmsDialogRowPhoneNumber = (props) => {
  const { rowClass, iconLeft, classes, onChange, value, required, disabled } =
    props;

  const languageMapping = () => {
    if (i18n.language === 'nlbe') return 'be';
    if (i18n.language === 'en') return 'gb';
    return i18n.language;
  };
  return (
    <Grid
      container
      className={rowClass}
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
    >
      {iconLeft && (
        <Grid item>
          <div className={classes.icon}>
            <RequiredIcon required={required}>{iconLeft}</RequiredIcon>
          </div>
        </Grid>
      )}
      <Grid item xs>
        <PhoneInput
          className={classes.PhoneInput}
          initialValueFormat="international"
          focusInputOnCountrySelection={true}
          international={true}
          defaultCountry={languageMapping()}
          value={value}
          onChange={(phone) => onChange(phone)}
          disabled={disabled}
        />
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(VmsDialogRowPhoneNumber);
