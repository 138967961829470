import PropTypes from 'prop-types';
import { withStyles, IconButton, Paper, Modal, Grid } from '@material-ui/core';
import {
  Event,
  FolderOpen,
  Chat,
  InsertDriveFile,
  FilterList,
  CheckBox,
  CheckBoxOutlineBlank,
  DateRange,
  EnhancedEncryption,
  EmojiPeople,
  Lock,
  Edit,
  ArrowUpwardOutlined,
} from '@material-ui/icons';

import {
  VmsInput,
  VmsDialogRowSelect,
  VmsDialogRowSelectCollection,
  VmsDialogRowSimpleDocumentCollection,
  VmsDialogRowCheckBoxCollection,
  VmsDialogFooter,
  VmsDialogHeader,
} from 'components';
import i18n from 'assets/i18n';
import modalStyle from 'assets/jss/modalStyle';
import commonStyle from 'assets/jss/commonStyles';
import { types, other_categories, delimiter } from 'AppSettings';
import { VmsDialogRowSimpleCollection } from './rows';
import { elevation } from 'assets/UISettings';

const styles = (theme) => ({
  ...modalStyle(theme, 400),
  ...commonStyle(theme),
});

const typeDialog = (props) => {
  const {
    classes,
    onSave,
    onClose,
    onChange,
    open,
    item,
    editMode,
    typeEditing,
    inspectionActivityTypes,
    menuAnchorEl,
    onMenuClose,
    onMenuOpen,
  } = props;
  const { documents, questionnaires, signed, feedback } = props.documents;
  const { documentsMenuOpen } = menuAnchorEl;
  const {
    inspectionActivities,
    accompanimentRequired,
    authenticationRequired,
    confirmationRequired,
    useForSelfRegistration,
    signInSelfRegistration,
  } = item.mixedType;

  // Transform available types to object required by VmsDialogRowSelect component
  let availableTypes = { key: [], value: [] };
  const keys = Object.keys(types);
  keys.forEach((key, idx) => {
    availableTypes.key[idx] = key;
    availableTypes.value[idx] = i18n.t(types[key]);
  });

  const documentTypeRequiredEntrance = Boolean(
    types[item.type] === types.questionnaire ||
      types[item.type] === types.document ||
      types[item.type] === types.signed_document
  );

  let visitPurposeOptions = [];

  let name = item.name;
  if (types[item.type] === types.visit_purpose) {
    // parse name for checked options
    let tokens = name.split(delimiter);
    name = tokens[0]; // Extract name of visit purpose

    let other_categories_values = Object.values(other_categories);
    other_categories_values.forEach((option) => {
      visitPurposeOptions.push({
        label: i18n.t(option),
        value: option,
        checked: tokens.lastIndexOf(option) > 0,
      });
    });
  }

  const saveDisabled = typeEditing
    ? name === '' || item.type === ''
    : name === '';
  const headerText = !typeEditing
    ? i18n.t('type_dialog_title_configuration_title')
    : editMode
    ? i18n.t('type_dialog_title_update')
    : i18n.t('type_dialog_title_new');
  const namePlaceholder = typeEditing
    ? i18n.t('type_dialog_placeholder_type_name')
    : i18n.t('type_dialog_placeholder_configuration_value');

  const onChangeName = (event) => {
    let name = event.target.value;
    let newName = [name];
    if (types[item.type] === types.visit_purpose) {
      // edit name for visit purpose
      visitPurposeOptions.forEach((option) => {
        if (option.checked) {
          newName.push(option.value);
        }
      });
    }
    newName = newName.join(delimiter);
    onChange('name', newName)();
  };

  const handleChangeVisitPurposeOption = (event) => {
    let value = event.target.value;
    let checked = event.target.checked;
    let newName = [name];
    let option = visitPurposeOptions.find((o) => o.value === value);
    if (option) {
      option.checked = checked;
    }
    visitPurposeOptions.forEach((option) => {
      if (option.checked) {
        newName.push(option.value);
      }
    });
    newName = newName.join(delimiter);
    onChange('name', newName)();
  };

  const handleChangeType = (event) => {
    let type = event.target.value;
    if (types[type] === types.visit_purpose) {
      let newName = [name];
      let other_categories_values = Object.values(other_categories);
      other_categories_values.forEach((value) => {
        newName.push(value);
      });
      newName = newName.join(delimiter);
      onChange('name', newName)();
    }
    if (types[type] !== types.visit_purpose) {
      // Make sure when we change from visit_purpose type to strip all the options
      onChange('name', name.split(delimiter)[0])();
    }
    onChange('type', type)();
  };

  const onChangeAccompaniment = (event) => {
    onChange('accompanimentRequired', !accompanimentRequired)(event);
  };

  const onChangeAuthentication = (event) => {
    onChange('authenticationRequired', !authenticationRequired)(event);
  };
  const onChangeConfirmation = (event) => {
    onChange('confirmationRequired', !confirmationRequired)(event);
  };

  const onChangeSelfRegistration = (event) => {
    onChange('useForSelfRegistration', !useForSelfRegistration)(event);
  };
  const onChangeSignInSelfRegistration = (event) => {
    onChange('signInSelfRegistration', !signInSelfRegistration)(event);
  };

  return (
    <Modal className={classes.modal} open={open} onClose={onClose}>
      <Paper className={classes.paper} elevation={elevation.light}>
        <VmsDialogHeader
          title={headerText}
          onClose={onClose}
          icon={<Event />}
        />
        <Grid container>
          {!typeEditing ? null : (
            <VmsDialogRowSelect
              rowClass={classes.dialogRow}
              iconLeft={<FolderOpen />}
              placeholder={i18n.t('type_dialog_placeholder_type_select')}
              onChange={handleChangeType}
              selectTypes={availableTypes}
              selectedType={item.type}
              required
            />
          )}
          <VmsInput
            rowClass={typeEditing ? classes.dialogRow2 : classes.dialogRow}
            iconLeft={<Chat />}
            textValue={name}
            placeholder={namePlaceholder}
            inputLabel={namePlaceholder}
            onChange={onChangeName}
            required
          />
          {types[item.type] === types.visitor_type && (
            <>
              <VmsInput
                inputName="accompanimentRequired"
                disabled={true}
                rowClass={classes.dialogRow2}
                iconLeft={<EmojiPeople />}
                iconRight={
                  <IconButton onClick={onChangeAccompaniment}>
                    {accompanimentRequired ? (
                      <CheckBox color="primary" />
                    ) : (
                      <CheckBoxOutlineBlank />
                    )}
                  </IconButton>
                }
                textValue={i18n.t('invite_form_accompaniment_required')}
                inputLabel={i18n.t('invite_form_accompaniment_required')}
              />
              <VmsInput
                inputName="authenticationRequired"
                disabled={true}
                rowClass={classes.dialogRow2}
                iconLeft={<Lock />}
                iconRight={
                  <IconButton onClick={onChangeAuthentication}>
                    {authenticationRequired ? (
                      <CheckBox color="primary" />
                    ) : (
                      <CheckBoxOutlineBlank />
                    )}
                  </IconButton>
                }
                textValue={i18n.t('type_dialog_authentication_required')}
                inputLabel={i18n.t('type_dialog_authentication_required')}
              />
              <VmsInput
                inputName="confirmationRequired"
                disabled={true}
                rowClass={classes.dialogRow2}
                iconLeft={<Lock />}
                iconRight={
                  <IconButton onClick={onChangeSelfRegistration}>
                    {useForSelfRegistration ? (
                      <CheckBox color="primary" />
                    ) : (
                      <CheckBoxOutlineBlank />
                    )}
                  </IconButton>
                }
                textValue={i18n.t('Used for self-registration')}
                inputLabel={i18n.t('Used for self-registration')}
              />
              <VmsInput
                inputName="confirmationRequired"
                disabled={true}
                rowClass={classes.dialogRow2}
                iconLeft={<Lock />}
                iconRight={
                  <IconButton onClick={onChangeConfirmation}>
                    {confirmationRequired ? (
                      <CheckBox color="primary" />
                    ) : (
                      <CheckBoxOutlineBlank />
                    )}
                  </IconButton>
                }
                textValue={i18n.t('Self-registration confirmation required')}
                inputLabel={i18n.t('Self-registration confirmation required')}
              />
              <VmsInput
                inputName="signInSelfRegistration"
                disabled={true}
                rowClass={classes.dialogRow2}
                iconLeft={<Lock />}
                iconRight={
                  <IconButton onClick={onChangeSignInSelfRegistration}>
                    {signInSelfRegistration ? (
                      <CheckBox color="primary" />
                    ) : (
                      <CheckBoxOutlineBlank />
                    )}
                  </IconButton>
                }
                textValue={i18n.t('sign_in_self_registration')}
                inputLabel={i18n.t('sign_in_self_registration')}
              />
              <VmsDialogRowSelectCollection
                //disabled={isVisitor}
                rowClass={classes.dialogRow2}
                iconLeft={<InsertDriveFile />}
                placeholder={i18n.t('invite_form_add_document')}
                onChange={onChange}
                inputName="documents-menu"
                selectTypesA={documents}
                selectTypesB={signed}
                selectTypesC={questionnaires}
                selectTypesD={feedback}
                selectedTypeA={''}
                selectedTypeB={''}
                selectedTypeC={''}
                selectedTypeD={''}
                headerTypeA={i18n.t('invite_form_documents_documents')}
                headerTypeB={i18n.t('invite_form_documents_signed')}
                headerTypeC={i18n.t('invite_form_documents_safety_trainings')}
                headerTypeD={i18n.t('invite_form_documents_feedback')}
                anchorEl={documentsMenuOpen}
                onMenuClose={onMenuClose}
                onMenuOpen={onMenuOpen}
              />
              {Boolean(item.mixedType.documents.length) && (
                <VmsDialogRowSimpleDocumentCollection
                  documents={item.mixedType.documents}
                  onChange={onChange}
                />
              )}
              <VmsDialogRowSelect
                rowClass={classes.dialogRow2}
                iconLeft={<InsertDriveFile />}
                placeholder={i18n.t('app_settings_type_inspection')}
                onChange={onChange('inspection_type')}
                selectTypes={inspectionActivityTypes}
                selectedType=""
                inputName="inspection_type"
              />
              {Boolean(inspectionActivities.length) && (
                <VmsDialogRowSimpleCollection
                  collection={inspectionActivities}
                  inputName="inspection_type_remove"
                  onChange={onChange}
                />
              )}
            </>
          )}
          {types[item.type] === types.visit_purpose && (
            <>
              <VmsDialogRowCheckBoxCollection
                rowClass={classes.dialogRow2}
                iconLeft={<FilterList />}
                label={i18n.t('visit_purpose_other_categories_selection')}
                onChange={handleChangeVisitPurposeOption}
                items={visitPurposeOptions}
              />
            </>
          )}
          {types[item.type] === types.questionnaire && (
            <>
              <VmsInput
                rowClass={classes.dialogRow2}
                iconLeft={<Edit />}
                textValue={item.number_of_questions}
                inputName="number_of_questions"
                inputType="number"
                inputProps={{ min: 1 }}
                placeholder={i18n.t('number_of_questions')}
                onChange={onChange('number_of_questions')}
              />
              <VmsInput
                rowClass={classes.dialogRow2}
                iconLeft={<Edit />}
                iconRight={
                  <IconButton
                    onClick={onChange(
                      'random_order_of_questions',
                      !item.random_order_of_questions
                    )}
                  >
                    {item.random_order_of_questions ? (
                      <CheckBox color="primary" />
                    ) : (
                      <CheckBoxOutlineBlank />
                    )}
                  </IconButton>
                }
                textValue={i18n.t('random_order_of_questions')}
                inputLabel={i18n.t('random_order_of_questions')}
                inputName="random_order_of_questions"
              />
            </>
          )}
          {documentTypeRequiredEntrance ? (
            <>
              <VmsInput
                rowClass={classes.dialogRow2}
                iconLeft={<EnhancedEncryption />}
                iconRight={
                  <IconButton
                    onClick={onChange(
                      'required_for_entrance',
                      !item.required_for_entrance
                    )}
                  >
                    {item.required_for_entrance ? (
                      <CheckBox color="primary" />
                    ) : (
                      <CheckBoxOutlineBlank />
                    )}
                  </IconButton>
                }
                textValue={i18n.t('type_dialog_required_for_entrance')}
                inputLabel={i18n.t('type_dialog_required_for_entrance')}
                inputName="required_for_entrance"
              />
              {item.required_for_entrance && (
                <VmsInput
                  rowClass={classes.dialogRow2}
                  iconLeft={<DateRange />}
                  textValue={item.expires_in}
                  inputName="expires_in"
                  placeholder={i18n.t('type_dialog_expires_in')}
                  inputLabel={i18n.t('type_dialog_expires_in')}
                  inputType="number"
                  inputProps={{ min: 1 }}
                  onChange={onChange('expires_in')}
                />
              )}
              <VmsInput
                rowClass={classes.dialogRow2}
                iconLeft={<ArrowUpwardOutlined />}
                textValue={item.priority}
                inputName="priority"
                placeholder={i18n.t('type_dialog_priority')}
                inputLabel={i18n.t('type_dialog_priority')}
                inputType="number"
                onChange={onChange('priority')}
                inputProps={{ min: 1 }}
              />
            </>
          ) : null}

          <VmsDialogFooter onSave={onSave} saveDisabled={saveDisabled} />
        </Grid>
      </Paper>
    </Modal>
  );
};

typeDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  editMode: PropTypes.bool.isRequired,
  typeEditing: PropTypes.bool.isRequired,
  item: PropTypes.shape({
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  }).isRequired,
  inspectionActivityTypes: PropTypes.object.isRequired,
};

export default withStyles(styles)(typeDialog);
