import AbstractService from 'services/AbstractService';
import VisitModel from 'services/models/VisitModel';
import TemplateService from 'services/TemplateService';
import Auth from 'modules/Auth';
import moment from 'moment';

class VisitServiceImpl extends AbstractService {
  /**
   * SignIn a user.
   *
   * @param {string} username
   * @param {string} password
   */
  invite(user_id, visitorTypes, group, details, personalData) {
    var endpoint = 'visits';
    var visit = new VisitModel(
      user_id,
      visitorTypes,
      group,
      details.delegate,
      details.arrival,
      details.departure,
      details.host_id,
      details.purpose,
      details.note,
      undefined,
      undefined,
      details.wifi_username,
      details.wifi_password,
      details.wifi_enabled,
      details.option1,
      details.option2,
      details.porterNote,
      details.accompanimentRequired,
      details.confirmationRequired,
      details.authenticationRequired,
      details.signInSelfRegistration,
      details.daysOff,
      details.datesOff,
      details.visitorCard
    );
    const headers = {
      'Content-Type': 'application/json',
      Authorization: Auth.getToken(),
    };

    var config = {
      headers: headers,
    };

    return this.post(endpoint, { visit: visit.toJSON(), personalData }, config);
  }

  /**
   * Get visit.
   *
   */
  getVisit(visit_id) {
    var endpoint = 'visits/' + visit_id;
    const headers = {
      'Content-Type': 'application/json',
      Authorization: Auth.getToken(),
    };

    var config = {
      headers: headers,
    };

    return this.get(endpoint, config);
  }

  /**
   * Update visit.
   *
   */
  update(
    visit_id,
    user_id,
    visitorTypes,
    group,
    details,
    groupMode,
    personalData
  ) {
    const endpoint = `visits/${visit_id}${groupMode ? '?groupMode=1' : ''}`;
    const visit = new VisitModel(
      user_id,
      visitorTypes,
      group,
      details.delegate,
      details.arrival,
      details.departure,
      details.host_id,
      details.purpose,
      details.note,
      undefined,
      undefined,
      details.wifi_username,
      details.wifi_password,
      details.wifi_enabled,
      details.option1,
      details.option2,
      details.porterNote,
      details.accompanimentRequired,
      details.confirmationRequired,
      details.authenticationRequired,
      details.signInSelfRegistration,
      details.daysOff,
      details.datesOff,
      details.visitorCard
    );
    const headers = {
      'Content-Type': 'application/json',
      Authorization: Auth.getToken(),
    };

    const config = {
      headers: headers,
    };
    return this.put(endpoint, { visit: visit.toJSON(), personalData }, config);
  }

  /**
   * Delete visit by id
   * @param {string} visit_id
   */
  deleteVisit(visit_id) {
    const endpoint = 'visits/' + visit_id;
    const headers = {
      Authorization: Auth.getToken(),
    };

    const config = {
      headers: headers,
    };

    return this.delete(endpoint, config);
  }

  getGroupVisits(group) {
    const endpoint = `visits/group/${encodeURIComponent(group)}`;
    const headers = {
      'Content-Type': 'application/json',
      Authorization: Auth.getToken(),
    };
    const config = {
      headers: headers,
    };
    return this.get(endpoint, config);
  }

  deleteGroupVisits(group) {
    const endpoint = `visits/group/${encodeURIComponent(group)}`;
    const headers = {
      'Content-Type': 'application/json',
      Authorization: Auth.getToken(),
    };
    const config = {
      headers: headers,
    };
    return this.delete(endpoint, config);
  }

  getUpcommingGroupVisitsWithVisitors() {
    const endpoint = 'visits/upcomming/group';
    const headers = {
      'Content-Type': 'application/json',
      Authorization: Auth.getToken(),
    };
    const config = {
      headers: headers,
    };
    return this.get(endpoint, config);
  }

  getListOfVisitorsByDate(date) {
    const endpoint = 'visits/date/' + moment(date).format('YYYY-MM-DD');
    const headers = {
      'Content-Type': 'application/json',
      Authorization: Auth.getToken(),
    };
    var config = {
      headers: headers,
    };
    return this.get(endpoint, config);
  }

  getListOfVisitsHost(user_id, type, page, limit) {
    const endpoint = `visits/host/${user_id}?type=${type}&page=${page}&limit=${limit}`;
    const headers = {
      'Content-Type': 'application/json',
      Authorization: Auth.getToken(),
    };
    var config = {
      headers: headers,
    };
    return this.get(endpoint, config);
  }

  getListOfUpcommingVisitor(user_id) {
    const endpoint = 'visits/upcomming/visitor/' + user_id;
    const headers = {
      'Content-Type': 'application/json',
      Authorization: Auth.getToken(),
    };
    var config = {
      headers: headers,
    };
    return this.get(endpoint, config);
  }

  getTodaysVisits(tenantId) {
    const endpoint = 'visits/todayscount/' + tenantId;

    const headers = {
      'Content-Type': 'application/json',
      Authorization: Auth.getToken(),
    };

    const config = {
      headers: headers,
    };
    return this.get(endpoint, config);
  }

  getHostVisitsSuggestions = (name, type) => {
    const endpoint = `visits/suggestions/host/${encodeURIComponent(
      name
    )}?type=${type}`;
    const headers = {
      'Content-Type': 'application/json',
      Authorization: Auth.getToken(),
    };
    var config = {
      headers: headers,
    };
    return this.get(endpoint, config);
  };

  getVisitSuggestions = (name) => {
    const endpoint = 'visits/visitor/' + encodeURIComponent(name);
    const headers = {
      'Content-Type': 'application/json',
      Authorization: Auth.getToken(),
    };
    var config = {
      headers: headers,
    };
    return this.get(endpoint, config);
  };

  getVisitingSuggestions = (name) => {
    const endpoint = 'visits/visiting/' + encodeURIComponent(name);
    const headers = {
      'Content-Type': 'application/json',
      Authorization: Auth.getToken(),
    };
    var config = {
      headers: headers,
    };
    return this.get(endpoint, config);
  };

  updatePorterData(visit_id, porterData) {
    const endpoint = 'visits/porter';
    const data = {
      visitId: visit_id,
      data: porterData,
    };
    const headers = {
      'Content-Type': 'application/json',
      Authorization: Auth.getToken(),
    };
    const config = {
      headers: headers,
    };
    return this.post(endpoint, JSON.stringify(data), config);
  }

  sendEmergencyWarning(category, templateState) {
    const endpoint = 'visits/emergency';
    const data = {
      category: category,
      title: templateState.title,
      body: TemplateService.draftToHtml(
        templateState.editor.getCurrentContent()
      ),
      recipients: templateState.recipientArray,
    };
    const headers = {
      'Content-Type': 'application/json',
      Authorization: Auth.getToken(),
    };
    var config = {
      headers: headers,
    };
    return this.post(endpoint, JSON.stringify(data), config);
  }

  downloadMyVisits(userId, type) {
    const endpoint = `visits/download/host/${userId}?type=${type}`;

    const headers = {
      'Content-Type': 'application/json',
      Authorization: Auth.getToken(),
    };

    const config = {
      headers: headers,
    };
    return this.get(endpoint, config);
  }

  addEvent(visit_id, type, date) {
    const endpoint = `visits/${visit_id}/events`;
    const headers = {
      Authorization: Auth.getToken(),
    };

    const data = {
      type,
      date,
    };

    const config = {
      headers: headers,
    };

    return this.post(endpoint, data, config);
  }

  deleteEvent(visit_id, event_id) {
    const endpoint = `visits/${visit_id}/events/${event_id}`;
    const headers = {
      Authorization: Auth.getToken(),
    };

    const config = {
      headers: headers,
    };

    return this.delete(endpoint, config);
  }

  addInspectionActivity(visit_id, data) {
    const endpoint = `visits/${visit_id}/inspection`;
    const headers = {
      Authorization: Auth.getToken(),
    };

    const config = {
      headers: headers,
    };

    return this.post(endpoint, data, config);
  }

  checkInspectionActivity(visit_id, activityId) {
    const endpoint = `visits/${visit_id}/inspection/${activityId}`;
    const headers = {
      Authorization: Auth.getToken(),
    };

    const config = {
      headers: headers,
    };

    return this.post(endpoint, null, config);
  }

  accompanyAVisit(visit_id, groupMode) {
    const endpoint = `visits/${visit_id}/accompaniment${
      groupMode ? '?groupMode=1' : ''
    }`;
    const headers = {
      Authorization: Auth.getToken(),
    };
    const config = {
      headers: headers,
    };
    return this.post(endpoint, null, config);
  }

  sendPin(data) {
    const endpoint = 'visit/sendPin';
    const headers = {
      Authorization: Auth.getToken(),
    };
    const config = {
      headers: headers,
    };
    return this.post(endpoint, data, config);
  }

  validatePin(data) {
    const endpoint = 'visit/validatePin';
    const headers = {
      Authorization: Auth.getToken(),
    };
    const config = {
      headers: headers,
    };
    return this.post(endpoint, data, config);
  }

  getVisitByUser(user_id) {
    const endpoint = `visit/${user_id}`;
    const headers = {
      Authorization: Auth.getToken(),
    };

    const config = {
      headers: headers,
    };

    return this.get(endpoint, config);
  }

  checkVisitorCards(data) {
    const endpoint = 'visitorCards';
    const headers = {
      Authorization: Auth.getToken(),
    };

    const config = {
      headers: headers,
    };

    return this.post(endpoint, data, config);
  }
}

const VisitService = new VisitServiceImpl();

export default VisitService;
