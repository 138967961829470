import axios from 'axios';
import { openProgressBar } from 'components/common/bars/ProgressBar';
import i18n from 'assets/i18n';
import { resolveError } from 'modules/ErrorResolver';
import VmsError from './VmsError';

class AbstractService {
  constructor() {
    var protocol = window.location.protocol;
    const locationPort = window.location.port;
    var port =
      protocol !== 'https:' || process.env.REACT_APP_WEBSERVICE ? 80 : 443; //80 : 443; //3333 : 3343; //Remain on 3333 because of Fargate
    const hostname = window.location.hostname;
    const hostport =
      locationPort === 80 || locationPort === 443 ? '' : `:${locationPort}`;
    this.api = protocol + '//' + hostname + ':' + port + '/api/'; //Default
    this.callbackUrl = `${protocol}//${hostname}${hostport}`;

    if (process.env.REACT_APP_API === 'test') {
      // REACT_APP_API=test npm start
      console.log('Test API is depreciated...');
      this.api = 'http://localhost:3333/api/'; //Default
    }
  }

  _post(endpoint, data, config = null) {
    var url = this.api + endpoint;
    console.log('AbstractService.post', url, data, config);
    return axios.post(url, data, config);
  }

  post(endpoint, data, config = null) {
    openProgressBar(true);
    return new Promise((resolve, reject) => {
      this._post(endpoint, data, config)
        .then((response) => {
          openProgressBar(false);
          if (response.data.success === false) {
            reject(this._errorFromResponse(response));
          } else {
            resolve(response.data);
          }
        })
        .catch((error) => {
          openProgressBar(false);
          reject(this._errorFromErrorResponse(error));
        });
    });
  }

  _put(endpoint, data, config = null) {
    var url = this.api + endpoint;
    console.log('AbstractService.put', url, data, config);
    return axios.put(url, data, config);
  }

  put(endpoint, data, config = null) {
    openProgressBar(true);
    return new Promise((resolve, reject) => {
      this._put(endpoint, data, config)
        .then((response) => {
          openProgressBar(false);
          if (response.data.success === false) {
            reject(this._errorFromResponse(response));
          } else {
            resolve(response.data);
          }
        })
        .catch((error) => {
          openProgressBar(false);
          reject(this._errorFromErrorResponse(error));
        });
    });
  }

  _get(endpoint, config = null) {
    var url = this.api + endpoint;
    console.log('AbstractService.get', url, config);
    return axios.get(url, config);
  }

  get(endpoint, config = null) {
    openProgressBar(true);
    return new Promise((resolve, reject) => {
      this._get(endpoint, config)
        .then((response) => {
          openProgressBar(false);
          console.log(response);
          if ((response.data || {}).success === false) {
            reject(this._errorFromResponse(response));
          } else {
            resolve(response.data);
          }
        })
        .catch((error) => {
          openProgressBar(false);
          reject(this._errorFromErrorResponse(error));
        });
    });
  }

  _delete(endpoint, config = null) {
    var url = this.api + endpoint;
    console.log('AbstractService.delete', url, config);
    return axios.delete(url, config);
  }

  delete(endpoint, config = null) {
    openProgressBar(true);
    return new Promise((resolve, reject) => {
      this._delete(endpoint, config)
        .then((response) => {
          openProgressBar(false);
          if (response.data.success === false) {
            reject(this._errorFromResponse(response));
          } else {
            resolve(response.data);
          }
        })
        .catch((error) => {
          openProgressBar(false);
          reject(this._errorFromErrorResponse(error));
        });
    });
  }

  _errorFromResponse(response) {
    const errorMsg = ((response.data || {}).error || {}).message;
    const errorCode = ((response.data || {}).error || {}).code;
    const status = response.status;
    let message = `${i18n.t('unexpected_error')} ${errorMsg}`;
    if (errorCode) {
      message = resolveError(errorCode);
    }
    return new VmsError(message, errorCode, false, response, status);
  }

  _errorFromErrorResponse(error) {
    const response = error.response;
    const isNetworkError = error.message === 'Network Error';
    let message = `${i18n.t('unexpected_error')} ${error.message}`;
    let errorCode = undefined;
    let status;

    if (isNetworkError) {
      message = i18n.t('network_error');
    }

    if (response) {
      const errorMsg = ((response.data || {}).error || {}).message;
      errorCode = ((response.data || {}).error || {}).code;
      if (errorMsg) {
        message = `${i18n.t('unexpected_error')} ${error.message}`;
      }
      if (errorCode) {
        message = resolveError(errorCode);
      }
      status = response.status;
    }

    return new VmsError(message, errorCode, isNetworkError, response, status);
  }
}

export default AbstractService;
